import axios from "axios";
import { BookmarkItem } from "../types";
import Bugsnag from "./bugsnag";

export async function getBookmarks(idToken: string) {
  try {
    const res = await axios.get(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}/bookmarks`,
    );
    const { data } = res;
    return data as BookmarkItem[];
  } catch (err) {
    Bugsnag.notify(err);
    return [];
  }
}

export async function updateBookmark(idToken: string, type: 'add' | 'remove', bookmark: BookmarkItem) {
  try {
    const body = {
      type,
      bookmark
    }
    const res = await axios.post(
      `${process.env.NEXT_PUBLIC_API_SERVER_URL}/users/${idToken}/bookmarks`,
      body,
    );
    const { data } = res;
    return data as BookmarkItem[];
  } catch (err) {
    Bugsnag.notify(err);
    return [];
  }
}