import React from "react";
import Dialog from "@material-ui/core/Dialog";
import DialogTitle from "@material-ui/core/DialogTitle";
import DialogContent from "@material-ui/core/DialogContent";
import DialogActions from "@material-ui/core/DialogActions";
import CloseIcon from "@material-ui/icons/Close";
import Button from '../CustomButtons/Button';
import { makeStyles } from "@material-ui/core/styles";
import javascriptStyles from "../../assets/jss/nextjs-material-kit-pro/pages/componentsSections/javascriptStyles";
import Slide from "@material-ui/core/Slide";

export interface Props {
  message: string;
  confirmButtonText: string;
  onConfirmButtonClick: () => void;
  closeButtonText: string;
  isOpen: boolean;
  setIsOpen: (val: boolean) => void;
}

const useStyles = makeStyles(javascriptStyles as any);

const Transition = React.forwardRef(function Transition(props: any, ref) {
  return <Slide direction="down" ref={ref} {...props} />;
});
Transition.displayName = "Transition";

export default function Modal(props: Props) {
  const { message, confirmButtonText, onConfirmButtonClick, closeButtonText, isOpen, setIsOpen} = props;
  const classes = useStyles();
  return (
    <Dialog
      classes={{
        root: classes.modalRoot,
        paper: classes.modal + " " + classes.modalSmall,
      }}
      open={isOpen}
      TransitionComponent={Transition as any}
      keepMounted
      onClose={() => setIsOpen(false)}
    >
      <DialogTitle
        disableTypography
        className={classes.modalHeader}
      >
        <Button
          simple
          className={classes.modalCloseButton}
          key="close"
          aria-label="Close"
          onClick={() => setIsOpen(false)}
        >
          {" "}
          <CloseIcon className={classes.modalClose} />
        </Button>
      </DialogTitle>
      <DialogContent
        className={classes.modalBody + " " + classes.modalSmallBody}
      >
        <h5>{message}</h5>
      </DialogContent>
      <DialogActions
        className={classes.modalFooter + " " + classes.modalFooterCenter}
      >
        <Button
          onClick={() => setIsOpen(false)}
          link
          className={classes.modalSmallFooterFirstButton}
        >
          {closeButtonText}
        </Button>
        <Button
          onClick={onConfirmButtonClick}
          color="success"
          simple
          className={
            classes.modalSmallFooterFirstButton +
            " " +
            classes.modalSmallFooterSecondButton
          }
        >
          {confirmButtonText}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
