import moment from 'moment';

const displayDatetime = (dateString) => moment(dateString).format('D/M/Y HH:mm');

const displayShortDateRange = (startDateString, endDateString) => {
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);
  const now = moment();
  /* console.log('dateRangeText', {startDate: startDate.format(), endDate: endDate.format(),
    diffMin: startDate.diff(endDate, 'minutes'), diffDay: startDate.diff(endDate, 'days')}); */

  if (startDate.format('YYYYMMDD') === endDate.format('YYYYMMDD')) {
    // same day
    return startDate.format('DD/MM');
  } else if (startDate.year() === endDate.year()) {
    // same year
    return `${startDate.format('DD/MM')} - ${endDate.format('DD/MM')}`;
  } else {
    // different year
    return `${startDate.format('DD/MM/YY')} - ${endDate.format('DD/MM/YY')}`;
  }
};

const displayLongDateRange = (startDateString, endDateString) => {
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);

  /* console.log('dateRangeText', {startDate: startDate.format(), endDate: endDate.format(),
    diffMin: startDate.diff(endDate, 'minutes'), diffDay: startDate.diff(endDate, 'days')}); */
  if (endDate.diff(startDate, 'days') === 0 || endDate.diff(startDate, 'minutes') === 24 * 60) {
    return startDate.format('D/M/Y');
  }
  return `${startDate.format('D/M/Y')} - ${endDate.format('D/M/Y')}`;
};

const displayTimeRange = (startDateString, endDateString) => {
  const startDate = moment(startDateString);
  const endDate = moment(endDateString);

  return `${startDate.format('HH:mm')} - ${endDate.format('HH:mm')}`;
};

export default displayShortDateRange;

export { displayShortDateRange, displayLongDateRange, displayDatetime, displayTimeRange };
