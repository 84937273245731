import { useAuthUser } from "next-firebase-auth";
import React from "react";
import { useRecoilState } from "recoil";
import { bookmarkSelector } from "../recoil/user";
import { updateBookmark } from "../services/bookmark";
import { BookmarkItem } from "../types";

export default function useUserBookmark() {
  const [bookmarks, setBookmarks] = useRecoilState(bookmarkSelector);
  const user = useAuthUser();
  const hasSignedIn = !!user?.id;

  const addBookmark = React.useCallback(
    async (bookmark: BookmarkItem) => {
      if (user?.getIdToken) {
        const newBookmarks = await updateBookmark(
          await user?.getIdToken(),
          "add",
          bookmark
        );
        setBookmarks(newBookmarks);
      }
    },
    [user?.getIdToken]
  );

  const removeBookmark = React.useCallback(
    async (bookmark: BookmarkItem) => {
      if (user?.getIdToken) {
        const newBookmarks = await updateBookmark(
          await user?.getIdToken(),
          "remove",
          bookmark
        );
        setBookmarks(newBookmarks);
      }
    },
    [user?.getIdToken]
  );

  const checkIsBookmarked = React.useCallback(
    (eventUid: string) => {
      if (!bookmarks) return false;
      return bookmarks.findIndex((item) => item.eventUid === eventUid) >= 0;
    },
    [bookmarks]
  );

  const canBookmark = React.useMemo(() => {
    return hasSignedIn;
  }, [hasSignedIn]);

  const toggleBookmark = React.useCallback(
    async (uid: string) => {
      const bookmarkItem = { eventUid: uid };
      if (canBookmark) {
        if (!checkIsBookmarked(uid)) {
          await addBookmark(bookmarkItem);
        } else {
          await removeBookmark(bookmarkItem);
        }
      }
    },
    [addBookmark, removeBookmark, checkIsBookmarked, canBookmark]
  );

  return {
    bookmarks,
    addBookmark,
    removeBookmark,
    checkIsBookmarked,
    canBookmark,
    toggleBookmark,
  };
}
